import React, { useEffect, useState } from "react"
import styled from "styled-components"
import queryString from 'query-string'
import Helmet from 'react-helmet'

import SEO from "../components/seo"

const BuilderPage = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;

  iframe {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
  }
`

const BuildPage = (props) => {
  const [builderParams, setBuilderParams] = useState(null);

  function onBuilderMessage(event) {
    const message = event.data;

    if (message.type === "SignSaved") {
      const urlParams = queryString.parse(typeof window !== `undefined` ? window.location.search : "");

      if (!urlParams.uid) {
        window.history.replaceState({}, "Design #" + message.data.id, "/build?uid=" + message.data.uid);
      }
    }
  }

  useEffect(
    () => {
        const urlParams = queryString.parse(typeof window !== `undefined` ? window.location.search : "");
        const params = [];

        if (urlParams.uid) 
          params.push("uid=" + urlParams.uid);
        if (urlParams.from)
          params.push("from=" + urlParams.from);
        if (urlParams.product)
          params.push("product=" + urlParams.product)
        if (urlParams.template)
          params.push("template=" + urlParams.template)
        if (urlParams.version)
          params.push("version=" + urlParams.version)

        if (params.length == 0)
          params.push("from=unknown");

        if (params.template && params.template == 1)
          setBuilderParams(params.join("&")+"#/panels/template");
        else
          setBuilderParams(params.join("&"));

        window.addEventListener("message", onBuilderMessage, false);    
    }, 
    []
  );

  return (
    <>
      <SEO page="build" title="Sign Builder" />
      <Helmet
          bodyAttributes={{
              class: 'noscroll'
          }}
      />    
      <BuilderPage>
        {builderParams && 
          <iframe src={process.env.GATSBY_BUILDER_BASE_URL + "?" + builderParams} title="Sign Builder" width="100%" height="100%" border="0" frameBorder="0" />
        }
      </BuilderPage>
    </>
  )
}

export default BuildPage
